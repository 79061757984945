export const Data = [
    { id: 1, title: "Hayvonot bog'iga sayohat", routeName: "animals", image: "/assets/images/1.png" },
    { id: 2, title: "Baliqlar bilan tanishuv", routeName: "fish", image: "/assets/images/2.png" },
    { id: 3, title: "O'rmonlar", routeName: "forests", image: "/assets/images/3.png" },
    { id: 4, title: "Cho'llar", routeName: "deserts", image: "/assets/images/4.png" },
    { id: 5, title: "Tomorqaga sayohat", routeName: "garden", image: "/assets/images/5.png" },
    { id: 6, title: "Uy hayvonlari", routeName: "pets", image: "/assets/images/6.png" },
]

export const Data1 = {
    animals: { id: 1, title: "Hayvonot bog'iga sayohat", routeName: "animals", poster: "/assets/images/poster/1.jpg", videoLink: "", music: "http://streaming.tdiradio.com:8000/house.mp3" },
    fish: { id: 2, title: "Baliqlar bilan tanishuv", routeName: "fish", poster: "/assets/images/poster/1.jpg", videoLink: "", music: "http://streaming.tdiradio.com:8000/house.mp3" },
    forests: { id: 3, title: "O'rmonlar", routeName: "forests-deserts", poster: "/assets/images/poster/1.jpg", videoLink: "", music: "" },
    deserts: { id: 4, title: "Cho'llar", routeName: "forests-deserts", poster: "/assets/images/poster/1.jpg", videoLink: "", music: "" },
    garden: { id: 5, title: "Tomorqaga sayohat", routeName: "garden", poster: "/assets/images/poster/1.jpg", videoLink: "", music: "" },
    pets: { id: 6, title: "Uy hayvonlari", routeName: "pets", poster: "/assets/images/poster/1.jpg", videoLink: "/assets/videos/pets.mp4", music: "http://streaming.tdiradio.com:8000/house.mp3" },
}

export const DataKeys = {
    animals: ["maymun", "yo'lbars", "qoplon", "fil", "jirafa", "tovus", "begemot"],
    fish: ["sazan", "kit", "akula", "gepard baliq"],
    forests: ["ayiq", "fil", "yo'lbars", ""],
    deserts: [],
    garden: [],
    pets: ["mushuk", "tovuq", "jo'ja", "sigir", "eshshak", "ho'kiz", "it"],
}