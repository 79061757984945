import "./style.css"
import { Link, useLocation } from "react-router-dom"

function Navbar() {
    const location = useLocation()
    return (
        <div className="header-navbar">
            <Link to="/">
                <img className="home-btn" src="/assets/images/home-btn.png" alt="Home Btn" />
            </Link>
            <Link to="/contents" className={`${location.pathname === '/contents' ? 'hide' : ''}`}>
                <img className="home-btn" src="/assets/images/play-btn.png" alt="Play Btn" />
            </Link>
        </div>
    )
}

export default Navbar
