import "./style.css"

function Home() {
    return (
        <header className="header">
            <div className="header-teacher" data-aos="fade-right" data-aos-offset="1">
                <img className="header-teacher--image" src="../../assets/images/fon.gif" alt="Monkey teacher" />
            </div>
            {/* <div className="header-message" data-aos="zoom-in-up">
                <img className="header-message--image" src="./assets/images/mess.png" alt="Monkey message" />
            </div> */}
        </header>
    )
}

export default Home
