import "video-react/dist/video-react.css";
import { useParams } from 'react-router-dom'
// import { BigPlayButton, ControlBar, ForwardControl, LoadingSpinner, PlaybackRateMenuButton, Player, ReplayControl, VolumeMenuButton } from 'video-react'
import "./style.css"
import { DataKeys, Data1 } from "../../utils/Data";
import { Badge } from "react-bootstrap";

function ContentVideo() {
    const { contentName } = useParams()
    return (
        <section className="videos">
            <h2 className="videos-title">{Data1[contentName]?.title}</h2>
            <div className="videos-container">
                <iframe
                    width="800"
                    height="450"
                    src="https://www.youtube-nocookie.com/embed/SMdNBKp4m6A?si=VxQ0s3yaxUgS0TP5"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
                {/* <Player
                    autoPlay={false}
                    playsInline
                    aspectRatio="16:9"
                    poster={Data1[params.contentName]?.poster}
                    src={Data1[params.contentName]?.videoLink}
                    style={{ boxShadow: "0 0 40px #000" }}
                >
                    <LoadingSpinner />
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={true}>
                        <ReplayControl seconds={10} order={2.1} />
                        <ForwardControl seconds={10} order={3.1} />
                        <VolumeMenuButton />
                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={6.1} />
                    </ControlBar>
                </Player> */}
            </div>
            <div className="keys-container">
                {
                    DataKeys[contentName].map(datakey => {
                        return <Badge bg="primary" className="videos-keys-text" key={datakey}>{datakey}</Badge>
                    })
                }
            </div>
        </section>
    )
}

export default ContentVideo