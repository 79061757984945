import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './pages/header/Home';
import Content from './pages/content/Content';
import Footer from './pages/footer/Footer';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import ContentVideo from './pages/content/ContentVideo';
import ErrorPage from './pages/error/ErrorPage';
AOS.init();

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contents" element={<Content />} />
        <Route path="contents/:contentName" element={<ContentVideo />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
